<template>
  <router-link
    :to="{
      name: 'ProfilOrderDetail',
      params: {
        id_order: order.no_pendaftaran
      }
    }"
    class="item-order d-block"
    >
    <div class="card card-info bg-neutral-200 border-0">
      <div class="card-body bg-transparent position-relative">
        <div class="card-body-info bg-white d-flex align-items-center">
          <div class="image-wrapper d-flex align-items-center justify-content-center rounded-circle bg-primary">
            <span class="initial fw-400 fs-14 lh-18 text-white">RH</span>
          </div>
          <div class="d-flex flex-column">
            <div class="info-name fw-700 fs-14 lh-18 color-neutral-900 mb-1">
              {{ order.ap_nama }}
            </div>
            <div class="info-group d-flex align-items-center gap-1 fw-300 fs-12 lh-14">
              <div class="info-group-item color-neutral-600">
                {{ order.no_pendaftaran }}
              </div>
              <div class="info-group-bullet rounded-circle bg-neutral-400" />
              <div class="info-group-item color-neutral-600">
                {{ order.tgl_daftar }}
              </div>
            </div>
          </div>
        </div>
        <div class="card-body-payment d-flex align-items-center justify-content-between">
          <div class="payment-group">
            <span class="payment-label fw-400 fs-12 lh-14 color-neutral-600">Total Pembayaran</span>
            <p class="payment-total fw-700 fs-14 lh-24 color-neutral-900 mb-0">Rp. {{ parseFloat(order.nominal)?.toLocaleString('id-ID') }}</p>
          </div>
          <div
            v-if="order.status_bayar === 'Sudah Dibayar'"
            class="icon-wrapper">
            <img
              src="@/assets/icon/stamp-paid.svg"
            />
          </div>
          <button
            v-if="order.status_bayar === 'Belum Dibayar'"
            class="btn bg-transparent color-neutral-600 align-items-center py-1"
            @click.prevent="openPopupDelete">
            <img
              src="@/assets/icon/trash.svg"
              alt=""
            />
          </button>
        </div>
      </div>
      <div class="card-footer bg-transparent border-top-0">
        <div class="card-footer-item d-flex align-items-center">
          <div class="icon-wrapper d-flex align-items-center justify-content-center bg-neutral-300 rounded-circle">
            <i class="ri-map-pin-2-fill fs-18 color-neutral-600"></i>
          </div>
          <div class="location d-flex flex-column">
            <div class="location-name fw-600 fs-14 lh-18 color-neutral-900">
              {{ order.cabang_periksa }}
            </div>
            <div class="location-address fw-400 fs-12 lh-14 color-neutral-600">
              {{ order.cabang_alamat }}
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="parseInt(order.status_hasil)"
        class="card-footer">
        <button
          class="btn btn-primary w-100 d-flex justify-content-center align-items-center"
          @click.prevent="download(order.no_pendaftaran)"
          :disabled="loading">
          {{ loading ? 'Memeriksa..' : 'Download Hasil' }}
          <i class="ri-download-cloud-line ms-2"></i>
        </button>
      </div>
    </div>
  </router-link>
  <!-- Modal -->
  <div class="modal modal-delete fade"
    :id="`modal-delete-${order.no_pendaftaran}`"
    tabindex="-1"
    aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content border-0">
          <div class="modal-body d-flex flex-column p-0">
            <div class="modal-body-group d-flex align-items-center gap-3">
              <div class="modal-body-icon">
                <img src="@/assets/icon/trash-2.svg" alt="">
              </div>
              <div class="modal-body-info text-start">
                <span class="modal-body-label fw-700 fs-14 lh-24 color-neutral-900">Hapus pesanan Nurul Huda?</span>
                <p class="modal-body-desc fw-400 fs-12 lh-22 color-neutral-600 mb-0">Anda akan menghapus pesanan ini dari daftar pesanan</p>
              </div>
            </div>
            <div class="group-button d-flex align-items-center gap-3">
              <button
                type="button"
                class="btn btn-primary-outline"
                data-bs-dismiss="modal">
                <span class="fw-600 fs-14 lh-20">Nanti Saja</span>
              </button>
              <button
                @click="deleteOrder(order.no_pendaftaran)"
                type="button" class="btn btn-danger">
                Hapus Pesanan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- Modal -->
</template>

<script>
import { Modal } from 'bootstrap'
import { deleteOrder, downloadHasil } from '@/core/profile'
import { ref } from '@vue/reactivity'

export default {
  props: {
    order: {
      type: Object
    }
  },
  setup (props) {
    const openPopupDelete = () => {
      const modal = new Modal(
        document.querySelector(`#modal-delete-${props.order.no_pendaftaran}`)
      )

      modal.show()
    }

    /**
     * Agar loading nya tiap komponen
     */
    const loading = ref(false)

    const download = async kode => {
      loading.value = true

      try {
        const url = await downloadHasil(kode)
        window.open(url, '_blank')
      } finally {
        loading.value = false
      }
    }

    return {
      openPopupDelete,
      deleteOrder,
      downloadHasil,
      loading,
      download
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.item-order {
  .card {
    border-radius: 12px;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-3px);
      border-color: $secondary;
    }

    &-body {
      padding: 8px;

      &-info {
        gap: 12px;
        border-radius: 11px;
        padding: 12px 8px;

        .image-wrapper {
          width: 36px;
          height: 36px;
        }

        .info-group {
          &-bullet {
            width: 4px;
            height: 4px;
          }
        }
      }

      &-payment {
        padding: 14px 8px 8px;
      }
    }

    &-footer {
      padding: 0px 8px 10px;

      &-item {
        gap: 12px;
        padding-top: 10px;
        box-shadow: 0px 1px 0px 0px #E7E7EC inset;

        .icon-wrapper {
          width: 34px;
          height: 34px;
        }

        .location {
          gap: 4px;

          .location-name, .location-address {
            word-wrap: break-word;
            white-space: pre-wrap;
          }
        }
      }
    }

    .price {
      font-size: .9rem;

      @media (min-width: 520px) {
        font-size: 1.25rem;
      }
    }
  }
}

.modal-delete {
  .modal-dialog {
    @media (max-width: 520px) {
      position: fixed;
      top: auto;
      right: auto;
      left: auto;
      bottom: 0;
      margin: 0;
    }

    .modal-content {
      width: 100% !important;
      padding: 22px 18px;
      border-radius: 20px !important;

      @media (max-width: 520px) {
        border-radius: 20px 20px 0px 0px !important;
      }

      .modal-body {
        .group-button {
          margin-top: 32px;

          button {
            width: 100%;
            padding: 10px;
          }
        }
      }
    }
  }
}

.modal.fade .modal-dialog {
  transform: translate3d(0, 100vh, 0);
  transition: transform 0.5s ease-out;
}

.modal.show .modal-dialog {
  transform: translate3d(0, 0, 0);
}
</style>
