<template>
  <div class="main-page">
    <div class="row">
      <div class="page-title d-flex justify-content-between align-items-center pb-2 pt-3">
        <div
          class="d-md-block fw-600 fs-14 lh-28 color-neutral-900 mx-3 mx-md-0"
          :class="{
            'd-none': showSearchInputOnMobile || search
          }">
          Pesanan
        </div>
        <div class="form-search d-flex">
          <div
            class="form-search-group d-md-inline d-flex align-items-center border rounded-pill"
            :class="{
              'd-none': !showSearchInputOnMobile && !search
            }">
            <input
              type="text"
              class="form-control search-input bg-transparent d-md-inline-block"
              placeholder="Cari pesanan...."
              v-model="keyword"
              autocomplete="off">
            <span
              class="input-group-text bg-transparent border-0"
              :class="{
                'd-md-none': !showSearchInputOnMobile
              }">
              <div
                class="box-icon"
                type="button"
                @click="keyword = ''"
              >
                <i v-if="keyword" class="ri-close-circle-fill fs-14 color-neutral-700"></i>
                <i v-else class="ri-search-line fs-14 color-neutral-500"></i>
              </div>
            </span>
          </div>
          <button
            class="btn btn-close-search pt-2 d-md-inline-block"
            :class="{
              'd-none': !showSearchInputOnMobile
            }">
            <i class="ri-close-line ri-xl"></i>
          </button>
        </div>
        <button
          v-if="!search"
          @click="search = true"
          class="btn d-md-none">
          <i class="ri-search-line"></i>
        </button>
      </div>
    </div>
    <div class="border-bottom d-none d-md-block"></div>
    <div class="pt-md-4 px-3 px-md-2 mb-3">
      <ul class="nav nav-tabs d-flex">
        <li class="nav-item flex-fill text-center" role="button">
          <a class="nav-link" :class="{'active': filter != 3}" @click="filter = 2">Pesan Online</a>
        </li>
        <li class="nav-item flex-fill text-center" role="button">
          <a class="nav-link" :class="{'active': filter == 3}" @click="filter = 3">Pesan Offline</a>
        </li>
      </ul>
    </div>
    <div v-if="filter != 3" class="navigate row flex-nowrap overflow-auto px-3 px-md-2">
      <div class="navigate-item px-0">
        <div
          @click="filter = 2"
          class="profile-nav pointer rounded-pill"
          :class="{
            [(filter === 2) ? 'active bg-primary text-white' : 'color-primary-500']: true
          }">
          <span class="fw-400 fs-14 lh-16 py-0">Semua</span>
        </div>
      </div>
      <div class="navigate-item px-0">
        <div
          @click="filter = 1"
          class="profile-nav pointer rounded-pill"
          :class="{
            [(filter === 1) ? 'active bg-primary text-white' : 'color-primary-500']: true
          }">
          <span class="fw-400 fs-14 lh-16 py-0">Sudah Dibayar</span>
        </div>
      </div>
      <div class="navigate-item px-0">
        <div
          @click="filter = 0"
          class="profile-nav pointer rounded-pill"
          :class="{
            [(filter === 0) ? 'active bg-primary text-white' : 'color-primary-500']: true
          }">
          <span class="fw-400 fs-14 lh-16 py-0">Belum Dibayar</span>
        </div>
      </div>
    </div>
    <div v-if="loading.order" class="item-content">
      <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
          <div class="not-empty d-flex flex-column align-items-center pt-3">
              <div class="mb-4">
                <img src="@/assets/icon/empty.png" alt="">
              </div>
              <div class="fm-poppins text-title-active fw-600 fs-32">Loading..</div>
          </div>
      </div>
    </div>
    <div
      v-else-if="keyword && filteredOrders.length > 0 && filter !== 3"
      class="item-content d-flex flex-column px-2 px-md-0 mb-3 mb-md-0" style="gap: 16px">
      <order-item
        v-for="order in filteredOrders.slice(page.start, page.end)"
        :key="order"
        :order="order"
      />
    </div>
    <div v-else-if="keyword && filteredOrders.length == 0" class="item-content">
      <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
          <div class="not-empty d-flex flex-column align-items-center pt-3">
              <div class="image-wrapper mb-4">
                <img src="@/assets/icon/empty.png" alt="">
              </div>
              <div class="title fw-700 color-neutral-900">Tidak ada hasil ditemukan</div>
              <p class="desc fw-400 color-neutral-600 text-center">Maaf pesanan yang anda cari tidak ditemukan, silahkan coba layanan lainnya.</p>
          </div>
      </div>
    </div>
    <div v-else-if="!loading.order && orders && orders.length > 0 && filter !== 3" class="item-content d-flex flex-column px-2 px-md-0 mb-3 mb-md-0" style="gap: 16px">
      <order-item
        v-for="order in orders.slice(page.start, page.end)"
        :key="order"
        :order="order"
      />
      <voucher-item
        v-for="voucher in dummy"
        :key="voucher"
        :voucher="voucher"
      />
      <v-pagination
        class="d-inline-flex justify-content-center my-3"
        v-model="page.current"
        :pages="page.max"
        :range-size="1"
        active-color="#8CC63F"
        @update:modelValue="changePage"
      />
    </div>
    <div v-else-if="!loading.order && offlineOrders && offlineOrders.length > 0 && filter === 3" class="item-content d-flex flex-column px-2 px-md-0 mb-3" style="gap: 16px">
      <offline-order-item
        v-for="order in offlineOrders"
        :key="order"
        :order="order"
      />
    </div>
    <div v-else class="item-content">
      <div class="d-flex align-items-center justify-content-center" style="height: 20rem">
          <div class="not-empty d-flex flex-column align-items-center pt-3">
              <div class="bd-highlight mb-4">
                  <img src="@/assets/icon/order-list.svg" alt="">
              </div>
              <div class="title fw-700 color-neutral-900">Tidak ada Pesanan</div>
              <p class="desc fw-400 color-neutral-600 text-center" style="max-width: 255px">Anda belum mempunyai pesanan apapun.</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, watch, reactive, onMounted, nextTick, computed } from 'vue'
import OrderItem from '@/components/profile/order/OrderItem'
import OfflineOrderItem from '@/components/profile/order/OfflineOrderItem'
import VoucherItem from '@/components/profile/order/VoucherItem'
import { orders, getOrders, loading, getOfflineOrders, offlineOrders } from '@/core/profile'
import { user } from '@/core/auth'
import { title } from '@/core/page'
import VPagination from '@hennge/vue3-pagination'

export default {
  components: {
    OrderItem,
    OfflineOrderItem,
    VoucherItem,
    VPagination
  },
  data () {
    return {
      showSearchInputOnMobile: false
    }
  },
  setup () {
    const search = ref(false)
    const filter = ref(2) // 0 = belum bayar, 1 = sudah bayar, 2 = semua
    const page = reactive({
      current: 1, // Current pasti 1 saat inisialisasi
      max: 1, // Nanti diupdate sesuai jumlah datanya
      start: 0,
      end: 3, // = perPage
      perPage: 3
    })

    const changePage = () => {
      page.end = page.perPage * page.current
      page.start = page.end - page.perPage
    }

    // Pakai watcher aja buat update
    // Data sesuai kategori
    watch(filter, async () => {
      if (filter.value === 3) {
        await getOfflineOrders({
          id_pendaftar: user.value.id
        })
      } else {
        await getOrders({
          pas_id_pendaftar: user.value.pas_id,
          id_pendaftar: user.value.id,
          lunas: filter.value
        })
      }
    })

    // Watch data orders untuk update pagination
    watch(orders, (newOrders) => {
      // Update Max
      page.max = Math.ceil(newOrders.length / page.perPage)

      // Reset yang lainnya
      page.current = 1
      page.start = 0
      page.end = page.perPage
    })

    watch(offlineOrders, (newOfflineOrders) => {
      // Update Max
      page.max = Math.ceil(newOfflineOrders.length / page.perPage)

      // Reset yang lainnya
      page.current = 1
      page.start = 0
      page.end = page.perPage
    })

    onMounted(async () => {
      nextTick(() => {
        title.value = 'Pesanan'
      })

      if (filter.value === 3) {
        await getOfflineOrders({
          id_pendaftar: user.value.id
        })
      } else {
        await getOrders({
          pas_id_pendaftar: user.value.pas_id,
          id_pendaftar: user.value.id,
          lunas: filter.value
        })
      }
    })

    const keyword = ref('')
    const filteredOrders = computed(() => {
      return orders.value ? orders.value.filter((data) => {
        return keyword.value.toLowerCase().split(' ').every(v => data.ap_nama.toLowerCase().includes(v))
      }) : []
    })

    const dummy = [
      {
        kode: 'PHARM_CHY',
        status: 'Paid',
        tanggal: '12/11/2022'
      },
      {
        kode: null,
        status: 'Unpaid',
        tanggal: '12/11/2022'
      },
      {
        kode: 'PHARM_CHY',
        status: 'Expired',
        tanggal: 'Expired'
      }
    ]

    return {
      orders,
      user,
      filter,
      loading,
      page,
      changePage,
      dummy,
      search,
      keyword,
      filteredOrders,
      offlineOrders
    }
  }
}
</script>

<style lang="scss" scoped>
.page-title {
  .form-search {
    height: 34px;

    @media (max-width: 540px) {
      width: 100%;
    }

    &-group {
      @media (max-width: 540px) {
        width: 100%;
      }
    }

    .search-input {
      box-sizing: border-box;
      outline: none;
      height: 34px;

      @media (max-width: 540px) {
        width: 100%;
      }
    }

    .search-input:focus{
      box-shadow: none;
    }

    .search-input::placeholder {
      color: #A0AEC0;
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
    }

    .border {
      border: 1px solid #E2E8F0 !important;
    }
  }
}

.btn-close-search:active {
  outline: none !important;
  box-shadow: none !important;
}

.navigate {
  gap: 12px;
  margin-bottom: 20px;

  @media (max-width: 540px) {
    margin-bottom: 12px;
  }

  &-item {
    width: auto;
  }

  .profile-nav {
    width: 100%;
    padding: 6px 12px;
    border: 1px solid #CBD5E0;
  }

  .profile-nav.active {
    color: #FF9D0A;
  }
}

.not-empty {
  .title {
    font-size: 18px;
    line-height: 28px;
  }

  .desc {
    font-size: 14px;
    line-height: 24px;
    max-width: 292px;
  }
}
</style>
