<template>
  <router-link
    :to="{
      name: 'ProfilOfflineOrderDetail',
      params: {
        id_order: order.id
      }
    }"
    class="item-order d-block"
    >
    <div class="card card-info bg-neutral-200 border-0">
      <div class="card-body bg-transparent position-relative">
        <div class="card-body-info bg-white d-flex align-items-center">
          <div class="d-flex flex-column">
            <div class="info-name fw-700 fs-14 lh-18 color-neutral-900 mb-1">
              {{ order.nama_cabang }}
            </div>
            <div class="info-group d-flex align-items-center gap-1 fw-300 fs-12 lh-14">
              <div class="info-group-item color-neutral-600">
                {{ order.tanggal }}
              </div>
            </div>
          </div>
        </div>
        <div class="card-body-payment d-flex align-items-center justify-content-between">
          <div class="payment-group">
            <span class="payment-label fw-400 fs-12 lh-14 color-neutral-600">Nomor Lab</span>
            <p class="payment-total fw-700 fs-14 lh-24 color-neutral-900 mb-0">{{ order.no_lab }}</p>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    order: {
      type: Object
    }
  },
  setup () {
    //
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.item-order {
  .card {
    border-radius: 12px;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-3px);
      border-color: $secondary;
    }

    &-body {
      padding: 8px;

      &-info {
        gap: 12px;
        border-radius: 11px;
        padding: 12px 8px;

        .image-wrapper {
          width: 36px;
          height: 36px;
        }

        .info-group {
          &-bullet {
            width: 4px;
            height: 4px;
          }
        }
      }

      &-payment {
        padding: 14px 8px 8px;
      }
    }

    &-footer {
      padding: 0px 8px 10px;

      &-item {
        gap: 12px;
        padding-top: 10px;
        box-shadow: 0px 1px 0px 0px #E7E7EC inset;

        .icon-wrapper {
          width: 34px;
          height: 34px;
        }

        .location {
          gap: 4px;

          .location-name, .location-address {
            word-wrap: break-word;
            white-space: pre-wrap;
          }
        }
      }
    }

    .price {
      font-size: .9rem;

      @media (min-width: 520px) {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
