<template>
  <router-link
    :to="{
      name: 'Home',
    }"
    class="item-order d-block"
    >
    <div class="card card-info bg-neutral-200 border-0">
      <div class="card-body bg-transparent d-flex flex-column">
        <div class="card-body-image">
          <img
            src="@/assets/dummy/voucher-1.svg"
          />
        </div>
        <div class="card-body-info bg-white">
          <div class="voucher-info d-flex align-items-center justify-content-between mb-2">
            <div class="voucher-info-group d-flex align-items-center">
              <div class="voucher-info-image d-flex align-items-center justify-content-center rounded-circle bg-primary">
                <img
                  src="@/assets/icon/coupon.svg"
                  alt=""
                />
              </div>
              <div class="voucher-info-value d-flex flex-column">
                <span class="label fw-300 fs-12 lh-14 color-neutral-600 mb-1">
                  Kode Voucher
                </span>
                <p class="code fw-700 fs-14 lh-18 color-neutral-900 mb-0">
                  {{ !voucher.kode ? '-' : voucher.kode }}
                </p>
              </div>
            </div>
            <button
              type="button"
              class="btn btn-voucher border">
              <span class="btn-title fw-600 fs-12 lh-18 color-primary-500">Salin</span>
            </button>
          </div>
          <div
            class="expired text-white d-flex align-items-center justify-content-between fw-400 fs-12 lh-12"
            :class="{
              'bg-info-500': voucher.status == 'Paid',
              'bg-neutral-600': voucher.status == 'Unpaid',
              'bg-danger-500': voucher.status == 'Expired',
            }"
          >
            <span>
              {{ checkStatus(voucher.status) }}
            </span>
            <span>{{ voucher.tanggal }}</span>
          </div>
        </div>
        <div class="card-body-payment d-flex align-items-center justify-content-between">
          <div class="payment-group">
            <span class="payment-label fw-400 fs-12 lh-14 color-neutral-600">Total Pembayaran</span>
            <p class="payment-total fw-700 fs-14 lh-24 color-neutral-900 mb-0">Rp. 356.000</p>
          </div>

          <!-- Button Sampah -->
          <button
            v-if="voucher.status != 'Paid'"
            class="btn bg-transparent color-neutral-600 align-items-center p-0">
            <div class="image-wrapper bg-white rounded-circle d-flex align-items-center justify-content-center">
              <img
                src="@/assets/icon/trash.svg"
                alt=""
              />
            </div>
          </button>

          <!-- Icon Paid -->
          <div
           v-if="voucher.status == 'Paid'"
            class="icon-wrapper">
            <img
              src="@/assets/icon/stamp-paid.svg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    voucher: {
      type: Object
    }
  },
  setup () {
    const checkStatus = (value) => {
      let text = ''
      if (value === 'Paid') {
        text = 'Berlaku hingga'
      } else if (value === 'Unpaid') {
        text = 'Batas Pembayaran'
      } else {
        text = 'Valid Date'
      }

      return text
    }

    return {
      checkStatus
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables';

.item-order {
  .card {
    border-radius: 12px;
    transition: all 0.3s ease-in-out;

    &:hover {
      transform: translateY(-3px);
    }

    &-body {
      gap: 8px;
      padding: 8px;

      &-image {
        img {
          width: 100%;
          border-radius: 12px;
        }
      }

      &-info {
        gap: 12px;
        border-radius: 11px;
        padding: 12px 8px;

        .image-wrapper {
          width: 36px;
          height: 36px;
        }

        .voucher-info {
          &-group {
            gap: 12px;
          }

          .btn-voucher {
            padding: 6px 20px;
          }

          .border {
            border: 1px solid #E2E8F0
          }
        }

        .expired {
          padding: 8px;
          border-radius: 8px;
        }
      }

      &-payment {
        padding-left: 8px;

        .image-wrapper {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
</style>
